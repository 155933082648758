import { useCallback, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import NextLink from 'next/link';
import { SpacingScale } from '@cbhq/cds-common';
import { Box, Grid, Group, VStack } from '@cbhq/cds-web/layout';
import { ThemeProvider } from '@cbhq/cds-web/system';
import { Link, TextLabel1, TextLabel2 } from '@cbhq/cds-web/typography';
import { useIsMobile } from ':dapp/hooks/useIsMobile';
import { OCSSubscribeModal } from ':dapp/pages/ocs/components/OCSSubscribeModal';
import { OutlineButton } from '../OutlineButton/OutlineButton';
import { ShopBanner } from './components/ShopBanner';
import { FooterImage } from './FooterImage';
const messages = defineMessages({
  communityHeading: {
    defaultMessage: 'Community',
    description: 'Heading for community links column'
  },
  socialHeading: {
    defaultMessage: 'Social',
    description: 'Heading for social links column'
  },
  subscribeButton: {
    defaultMessage: 'Subscribe for updates',
    description: 'Text to be displayed in the subscribe button in the footer'
  },
  footerBlurb: {
    defaultMessage: 'Onchain Summer is a multi-month celebration of onchain art, music, culture gaming, and more. Join us to take part in a more creative internet.',
    description: 'Text to be displayed in the footer'
  }
});
const links = {
  community: [{
    title: 'Blog',
    url: 'https://paragraph.xyz/@onchaindaily/subscribe'
  }, {
    title: 'Build with us',
    url: 'https://www.base.org/'
  }, {
    title: 'Terms of Service',
    url: '/ocs/termsOfService'
  }],
  social: [{
    title: 'Twitter',
    url: 'https://twitter.com/CoinbaseWallet'
  }, {
    title: 'Farcaster',
    url: 'https://warpcast.com/coinbasewallet'
  }]
};
const imageContainerClass = "i16m1ddx";
const outlineBtnClass = "oma9ksv";
export function Footer() {
  const {
    formatMessage
  } = useIntl();
  const [openSubscribeModal, setOpenSubscribeModal] = useState(false);
  const handleOpenSubscribeModal = useCallback((toggle: boolean) => () => {
    setOpenSubscribeModal(toggle);
  }, [setOpenSubscribeModal]);
  const isMobile = useIsMobile();
  const dynamicStyles: {
    templateColumns: string | undefined;
    groupMaxWidth: string;
    spacingBottom: SpacingScale;
    spacingHorizontal: SpacingScale;
    spacingVertical: SpacingScale;
    groupGap: SpacingScale;
    groupDirection: 'vertical' | 'horizontal';
  } = useMemo(() => ({
    templateColumns: isMobile ? undefined : 'repeat(auto-fit, minmax(420px, 1fr))',
    groupMaxWidth: isMobile ? '100%' : '50%',
    spacingBottom: isMobile ? 0 : 9,
    spacingHorizontal: isMobile ? 0 : 4,
    spacingVertical: isMobile ? 5 : 10,
    groupGap: isMobile ? 8 : 10,
    groupDirection: isMobile ? 'vertical' : 'horizontal'
  }), [isMobile]);
  return <ThemeProvider>
      <div className={"d6kqvyd"}>
        <ShopBanner />
        <Grid testID="footer" width="100%" templateColumns={dynamicStyles.templateColumns} gap={dynamicStyles.spacingVertical} alignItems="flex-start" spacingHorizontal={dynamicStyles.spacingHorizontal} spacingVertical={dynamicStyles.spacingVertical}>
          <Box className={imageContainerClass}>
            <FooterImage />
          </Box>
          <VStack alignItems="flex-start">
            <TextLabel2 as="p" mono spacingBottom={4}>
              {formatMessage(messages.footerBlurb)}
            </TextLabel2>
            <Group spacingBottom={4} maxWidth={dynamicStyles.groupMaxWidth} width="100%">
              <OutlineButton buttonText={formatMessage(messages.subscribeButton)} onClick={handleOpenSubscribeModal(true)} textClass={`${outlineBtnClass}`} />
            </Group>
            <Group spacingBottom={dynamicStyles.spacingBottom} gap={dynamicStyles.groupGap} direction={dynamicStyles.groupDirection}>
              <Box flexGrow={1}>
                <VStack as="ul" gap={2} spacingEnd={1}>
                  <TextLabel1 as="span" mono spacingBottom={0.5}>
                    {formatMessage(messages.communityHeading)}
                  </TextLabel1>
                  {links.community.map(link => <NextLink href={link.url} target={link.title === 'Terms of Service' ? '_self' : '_blank'} key={link.url}>
                      <TextLabel2 as="span" mono color="foregroundMuted">
                        {link.title}
                      </TextLabel2>
                    </NextLink>)}
                </VStack>
              </Box>
              <Box flexGrow={1}>
                <VStack as="ul" gap={2}>
                  <TextLabel1 as="span" mono spacingBottom={0.5}>
                    {formatMessage(messages.socialHeading)}
                  </TextLabel1>
                  {links.social.map(link => <Link to={link.url} openInNewWindow key={link.url}>
                      <TextLabel2 as="span" mono color="foregroundMuted">
                        {link.title}
                      </TextLabel2>
                    </Link>)}
                </VStack>
              </Box>
            </Group>
          </VStack>
        </Grid>
      </div>
      {openSubscribeModal && <OCSSubscribeModal handleClose={handleOpenSubscribeModal(false)} />}
    </ThemeProvider>;
}

require("./Footer.linaria.module.css!=!../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Footer.tsx");