import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Box } from '@cbhq/cds-web/layout';
import { TextBody } from '@cbhq/cds-web/typography';
import { useIsMobile } from ':dapp/hooks/useIsMobile';
import { RouterPathname } from ':dapp/utils/RoutesEnum';
const messages = defineMessages({
  shopBlurb: {
    defaultMessage: 'Summer Shop powered by Slice',
    description: 'Text related to shop to be displayed in the footer'
  }
});
export function ShopBanner() {
  const {
    formatMessage
  } = useIntl();
  const router = useRouter();
  const isOCSShopEnabled = useIsFeatureEnabled('ocs_shop_tab');
  const isMobile = useIsMobile();
  const ALLOWED_PATHS = [RouterPathname.OCS_SHOP, RouterPathname.OCS_MERCHANT, RouterPathname.OCS_PRODUCT, RouterPathname.OCS_ORDER_HISTORY, RouterPathname.OCS_ORDER_DETAILS, RouterPathname.OCS_CHECKOUT, RouterPathname.OCS_EXT_REWARDS];
  const displayBanner = ALLOWED_PATHS.some(name => router.pathname.includes(name));
  const dynamicStyles = useMemo(() => ({
    boxHeight: isMobile ? '64px' : '128px'
  }), [isMobile]);
  if (!isOCSShopEnabled || !displayBanner) return null;
  return <>
      <a className={"a1g0xfxe"} href="https://slice.so/" rel="noopener noreferrer" target="_blank">
        <Image src="https://go.wallet.coinbase.com/static/sl-logo-w.png" alt="Slice Logo" height={20} width={20} />
        <TextBody as="p" color="foreground" spacingHorizontal={0}>
          {formatMessage(messages.shopBlurb)}
        </TextBody>
      </a>
      <Box height={dynamicStyles.boxHeight} />
    </>;
}

require("./ShopBanner.linaria.module.css!=!../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./ShopBanner.tsx");