import { useCallback, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useExecuteEmailSubscribe } from 'cb-wallet-data/hooks/Gamification/useEmailSubscribe';
import { LocalStorageStoreKey } from 'cb-wallet-store/models/LocalStorageStoreKey';
import { Store } from 'cb-wallet-store/Store';
import { Button } from 'wallet-cds-web/components/Button';
import { MiamiIllustrationWrapper } from 'wallet-cds-web/components/MiamiIllustrationWrapper/MiamiIllustrationWrapper';
import { useToggler } from '@cbhq/cds-common';
import { TextInput } from '@cbhq/cds-web/controls';
import { SpotRectangle } from '@cbhq/cds-web/illustrations';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { TextBody } from '@cbhq/cds-web/typography';

import { HomebaseModal } from ':dapp/components/HomebaseModal/HomebaseModal';
import { useIsMobile } from ':dapp/hooks/useIsMobile';

export const StoreKeys_emailSubscribeKey = new LocalStorageStoreKey('emailSubscribeKey');

const messages = defineMessages({
  modalTitle: {
    defaultMessage: 'Never miss a drop',
    description: 'Subscribe modal title',
  },
  modalBody: {
    defaultMessage: `Get notified about Onchain Summer's latest drops and experiences.`,
    description: 'Subscribe modal body',
  },
  modalCta: {
    defaultMessage: `Subscribe`,
    description: 'Subscribe modal CTA',
  },
  modalBodyCompleted: {
    defaultMessage: `Hooray! You signed up for Onchain Summer’s drop notifications. See you in your inbox.`,
    description: 'Subscribe completed modal body',
  },
  modalCtaCompleted: {
    defaultMessage: `Got it`,
    description: 'Subscribe completed modal CTA',
  },
});

type OCSSubscribeModalProps = {
  handleClose: () => void;
};

export function OCSSubscribeModal({ handleClose }: OCSSubscribeModalProps) {
  const [visible, { toggleOff }] = useToggler(true);
  const { formatMessage } = useIntl();
  const isMobile = useIsMobile();
  const [errorText, setErrorText] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string>('');
  const { mutateAsync: executeSubscribe, isLoading } = useExecuteEmailSubscribe();
  const [completedMode, setCompletedMode] = useState(
    Store.get<boolean>(StoreKeys_emailSubscribeKey) || false,
  );

  const handleOnClose = useCallback(() => {
    handleClose();
    toggleOff();
  }, [toggleOff, handleClose]);
  const handleCTAClick = useCallback(async () => {
    if (errorText) return null;

    await executeSubscribe({ emailAddress: email });
    setCompletedMode(true);
    Store.set<boolean>(StoreKeys_emailSubscribeKey, true);
  }, [email, errorText, executeSubscribe]);

  const handleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEmail(value);
    if (
      // email validation taken from `checkout` page
      !value ||
      !/^\S+@\S+\.\S+$/.test(value?.trim() || '')
    ) {
      setErrorText('Error: Invalid email format');
    } else {
      setErrorText(undefined);
    }
  }, []);
  return (
    <HomebaseModal
      title={formatMessage(messages.modalTitle)}
      onClose={handleOnClose}
      visible={visible}
      testID="subscribe-modal"
      spacingHorizontal={isMobile ? 3 : undefined}
    >
      <VStack gap={4} spacing={isMobile ? 0 : 3}>
        <HStack justifyContent="center">
          <MiamiIllustrationWrapper
            illustration="PrimeDeFiRectangleMiami"
            isMiamiIllustration
            isMiamiViceIllustration
          >
            <SpotRectangle name="protectedNotes" />
          </MiamiIllustrationWrapper>
        </HStack>
        {!completedMode ? (
          <>
            <TextBody as="span" color="foregroundMuted" align="center">
              {formatMessage(messages.modalBody)}
            </TextBody>
            <TextInput
              label="Email"
              helperText={errorText}
              variant={errorText ? 'negative' : undefined}
              placeholder="Email address"
              onChange={handleInputChange}
              value={email}
              testID="subscribe-email-input"
            />
          </>
        ) : (
          <TextBody as="span" color="foregroundMuted" align="center">
            {formatMessage(messages.modalBodyCompleted)}
          </TextBody>
        )}
        <Button
          loading={isLoading}
          onPress={!completedMode ? handleCTAClick : handleOnClose}
          variant="primary"
          block
          testID="subscribe-cta-button"
        >
          {!completedMode
            ? formatMessage(messages.modalCta)
            : formatMessage(messages.modalCtaCompleted)}
        </Button>
      </VStack>
    </HomebaseModal>
  );
}
